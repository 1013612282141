.responsive-input {
    position: relative !important;

    flex: none !important;

    box-sizing: content-box !important;
    min-width: 0.54em !important;
    height: 100%;
    padding-left: 0.54em !important;

    font: inherit;

    background: none;
    border: 0;

    appearance: textfield;
}

.responsive-input::-webkit-outer-spin-button,
.responsive-input::-webkit-inner-spin-button {
    margin: 0;

    appearance: none;
}
