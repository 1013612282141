/* stylelint-disable */
@import '~react-calendar/dist/Calendar.css';
@import '~@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import '~react-date-picker/dist/DatePicker.css';

.react-calendar {
	@include box-shadow($dropdown-box-shadow);
	width: 300px;
	padding: 1rem;

	background-color: $datepicker-dropdown-bg;

	// font-size: 1rem;
	border-color: $datepicker-dropdown-border;
	border-radius: $datepicker-border-radius;

	// padding: $datepicker-dropdown-padding;
	transition: $datepicker-cell-transition;

	&--doubleView {
		width: 600px;
	}

	button {
		&:focus {
			outline: none;
		}
	}

	&__navigation {
		&__label {
			white-space: nowrap;
		}

		button {
			border-radius: $datepicker-header-cell-border-radius;
			transition: $datepicker-cell-transition;

			&:enabled {
				&:hover {
					background-color: $component-hover-bg;
				}

				&:focus {
					background-color: transparent;
				}
			}

			&[disabled] {
				background-color: $datepicker-disabled-cell-color;
			}
		}
	}

	&__month-view {
		// &__weekdays {
		//     text-align: center;
		//     text-transform: uppercase;
		//     font-weight: bold;
		//     font-size: .75em;

		//     &__weekday {
		//         padding: .5em;
		//     }
		// }

		// &__weekNumbers {
		//     font-weight: bold;

		//     .react-calendar__tile {
		//         display: flex;
		//         align-items: center;
		//         justify-content: center;
		//         font-size: .75em;
		//         padding: calc(.75em / .75) calc(.5em / .75);
		//     }
		// }

		&__days {
			&__day {
				&--weekend {
					color: $danger;
				}

				&--neighboringMonth {
					color: $datepicker-disabled-old-new-color;
				}
			}
		}
	}

	&__year-view,
	&__decade-view,
	&__century-view {
		.react-calendar__tile {
			padding: 2em 0.5em;
		}
	}

	&__tile {
		max-width: 100%;
		padding: 0.75em 0.5em;
		text-align: center;
		background: none;

		&:disabled {
			background-color: $datepicker-disabled-cell-color;
		}

		&:enabled {
			&:hover {
				background-color: $component-hover-bg;
			}

			&:focus {
				background: none;
			}
		}

		// &--now {
		//     background: $info;
		//     color: white;

		//     &.react-calendar__month-view__days__day:not(.react-calendar__tile--active) {
		//         border-radius: 50%;
		//     }

		//     &:enabled {

		//         &:hover,
		//         &:focus {
		//             background: lighten($info, 10%);
		//         }
		//     }
		// }

		&--active {
			color: white;
			background: $primary;

			&:enabled {
				&:hover,
				&:focus {
					background: lighten($primary, 10%);
				}
			}
		}

		&--hasActive {
			color: white;
			background: $primary;

			&:enabled {
				&:hover,
				&:focus {
					background: lighten($primary, 10%);
				}
			}
		}

		&--rangeStart {
			&.react-calendar__month-view__days__day {
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
			}
		}

		&--rangeEnd {
			&.react-calendar__month-view__days__day {
				border-top-right-radius: 50%;
				border-bottom-right-radius: 50%;
			}
		}
	}

	&--selectRange {
		.react-calendar__tile {
			&--hover {
				background-color: $component-hover-bg;

				&.react-calendar__tile--now {
					color: black;
					background-color: $component-hover-bg;
					border-radius: 0;
				}
			}
		}
	}
}

.react-date-picker {
	&__calendar {
		width: unset;
	}

	&__button {
		&__icon {
			stroke: $gray-600;
		}

		&:enabled {
			&:hover,
			&:focus {
				.react-date-picker__button__icon {
					stroke: $primary;
				}
			}
		}
	}

	&__inputGroup {
		min-width: auto;
		white-space: nowrap;
	}

	&__range-divider {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	&__wrapper {
		@extend .form-control;
		display: flex;
		height: 100%;
		padding: 0;
		border: 0;

		input,
		button {
			outline: none;
		}
	}

	&--disabled {
		.react-date-picker__wrapper {
			opacity: 1;
			background-color: $gray-200;
		}
	}

	&--open {
		.react-date-picker__wrapper {
			color: $input-focus-color;
			background-color: $input-focus-bg;
			border-color: $input-focus-border-color;
			outline: 0;

			// Avoid using mixin so we can pass custom focus shadow properly
			@if $enable-shadows {
				box-shadow: $input-box-shadow, $input-focus-box-shadow;
			} @else {
				box-shadow: $input-focus-box-shadow;
			}
		}
	}
}

.react-daterange-picker {
	&__calendar {
		width: unset;
	}

	&__button {
		&__icon {
			stroke: $gray-600;
		}

		&:enabled {
			&:hover,
			&:focus {
				.react-daterange-picker__button__icon {
					stroke: $primary;
				}
			}
		}
	}

	&__inputGroup {
		min-width: auto;
		white-space: nowrap;
	}

	&__range-divider {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	&__wrapper {
		@extend .form-control;
		display: flex;

		input,
		button {
			outline: none;
		}
	}

	&--disabled {
		.react-daterange-picker__wrapper {
			opacity: 1;
			background-color: $gray-200;
		}
	}

	&--open {
		.react-daterange-picker__wrapper {
			color: $input-focus-color;
			background-color: $input-focus-bg;
			border-color: $input-focus-border-color;
			outline: 0;

			// Avoid using mixin so we can pass custom focus shadow properly
			@if $enable-shadows {
				box-shadow: $input-box-shadow, $input-focus-box-shadow;
			} @else {
				box-shadow: $input-focus-box-shadow;
			}
		}
	}
}

.is-invalid {
	.react-daterange-picker__wrapper {
		border-color: $danger;
	}
}

.is-valid {
	.react-daterange-picker__wrapper {
		border-color: $success;
	}
}
