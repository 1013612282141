/* stylelint-disable */

.breadcrumb-item + .breadcrumb-item::before {
	content: '-';
}

// .breadcrumb-item {
//     font-size: .875rem;
// }

.breadcrumb-dark {
	background-color: $default;

	.breadcrumb-item {
		font-weight: 600;

		a {
			color: $gray-100;
		}
	}
}

.breadcrumb-links {
	margin: 0;
	padding: 0;
	background: transparent;
}
