/* stylelint-disable */
.navbar-bg {
	content: ' ';

	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100%;
	height: 8rem;

	background-color: $bg-color;
}

.navbar-main {
	align-items: center;

	padding-right: 0;
	padding-left: 0;

	color: $nav-link-light-color;

	background-color: transparent !important;
}

.navbar-main {
	.active {
		.nav-link {
			font-weight: 700;
			color: $nav-link-light-color;
		}
	}

	.navbar-text {
		color: $nav-link-light-color;
	}

	.navbar-nav {
		flex-direction: row;
	}

	.nav-item {
		font-size: 0.875rem;
	}

	a {
		color: darken($nav-link-light-color, 5%);

		&:hover {
			color: $nav-link-light-color;
		}

		&.disabled {
			color: $nav-link-light-color;
			opacity: 0.6;
		}
	}

	.nav-link {
		height: 100%;
		padding-top: 0 !important;
		padding-right: 0.5rem !important;
		padding-bottom: 0 !important;
		padding-left: 0.5rem !important;

		color: darken($nav-link-light-color, 5%);

		&:hover {
			color: $nav-link-light-color;
		}

		&.disabled {
			color: $nav-link-light-color;
			opacity: 0.6;
		}

		&.nav-link-lg {
			div {
				margin-top: 3px;
			}

			i {
				margin-left: 0 !important;
				font-size: 1.125rem;
				line-height: 32px;
			}
		}

		&.nav-link-user {
			padding-top: 4px;
			padding-bottom: 4px;
			font-weight: 600;
			color: $nav-link-light-color;

			img {
				width: 30px;
			}
		}

		&.nav-link-img {
			overflow: hidden;
			padding-top: 4px;
			padding-bottom: 4px;
			border-radius: 50%;

			.flag-icon {
				@include shadow;
				width: 22px;
				height: 22px;

				line-height: 18px;

				background-size: cover;
				border-radius: 50%;
			}
		}
	}

	.navbar__title {
		font-size: 1.15rem;
		font-weight: 600;
		line-height: 1.5;
		color: $nav-link-light-color;
	}
}
