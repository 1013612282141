.accordion {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;

    .accordion-header, .accordion-body {
      padding: 10px 15px;
    }

    .accordion-header {
      cursor: pointer;
      background-color: #f9f9f9;
      border-radius: 3px;
      transition: all .5s;

      h4 {
        margin: 0;
        font-size: .875rem;
        font-weight: 700;
        line-height: 1;
      }

      &:hover {
        background-color: #f2f2f2;
      }

      &[aria-expanded="true"] {
        @include shadow-lighten($primary);
        color: #fff;
        background-color: $primary;
      }
    }

    .accordion-body {
      line-height: 24px;
    }

    >.card {
      margin-bottom: 0;
      border-top: 1px solid $light;
      border-radius: 0;
      box-shadow: none;
    }

    .card-header::after {
        content: none;
    }
  }  