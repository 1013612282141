.sweet-alert__icon {
    position: relative;

    box-sizing: content-box;
    width: 88px;
    height: 88px;
    margin: 20px auto;

    >* {
        position: absolute;
        z-index: 999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        margin: auto;
    }
}