
.offer-card {
    min-width: 350px;

    &__caption {
        bottom: 0;

        // top: 0;
        // bottom: initial;
        left:0;

        width: 100%;
        padding: 10px;

        background-color: rgba($color: #fff, $alpha: .5);
    }
}