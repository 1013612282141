/* stylelint-disable */

.wizard-steps {
	counter-reset: wizard-counter;
	display: flex;
	margin: 0 -10px;
	margin-bottom: 30px !important;

	.wizard-step {
		@include card-base;
		flex-basis: 0;
		flex-grow: 1;

		margin: 0 10px;
		padding: 25px;

		text-align: center;

		&:not(:disabled),
		&:not(.disabled) {
			cursor: pointer;
		}

		// &:before {
		//   counter-increment: wizard-counter;
		//   content: counter(wizard-counter);
		//   position: absolute;
		//   bottom: -40px;
		//   left: 50%;
		//   transform: translateX(-50%);
		//   width: 20px;
		//   height: 20px;
		//   line-height: 21px;
		//   font-size: .625rem;
		//   font-weight: 700;
		//   border-radius: 50%;
		//   background-color: $light;
		// }
		&.active {
			color: #fff;

			// @include shadow-lighten($primary);
			background-color: $primary;

			// &:before {
			//   background-color: $primary;
			//   color: #fff;
			// }
		}

		&.hover:hover {
			@include shadow-lighten($primary);
		}

		&.wizard-step-success {
			color: #fff;
			background-color: $success;

			&::before {
				color: #fff;
				background-color: $success;
			}
		}

		&.wizard-step-danger {
			color: #fff;
			background-color: $danger;

			&::before {
				color: #fff;
				background-color: $danger;
			}
		}

		&.wizard-step-warning {
			color: #fff;
			background-color: $warning;

			&::before {
				color: #fff;
				background-color: $warning;
			}
		}

		&.wizard-step-info {
			color: #fff;
			background-color: $info;

			&::before {
				color: #fff;
				background-color: $info;
			}
		}

		.wizard-step-icon {
			.fas,
			.far,
			.fab,
			.fal,
			.ion,
			.svg-inline--fa {
				margin-bottom: 15px;
				font-size: 2.125rem;
			}
		}

		.wizard-step-label {
			font-size: 0.625rem;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
}

@include media-breakpoint-down(xs) {
	.wizard-steps:not(.wizard-tabs) {
		display: block;

		.wizard-step:not(.active) {
			display: none;
		}
	}

	.wizard-steps {
		.wizard-step {
			min-width: calc(50% - 20px);
			margin-bottom: 30px;
		}
	}
}
