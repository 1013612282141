@include media-breakpoint-up(md) {
    .main-content .container-fluid {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}
@include media-breakpoint-down(xs) {
    .main-content .container-fluid {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
