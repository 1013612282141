/* stylelint-disable */
.select-item {
	cursor: pointer;

	position: relative;

	background-color: $secondary;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border: 1px solid #fff;
}

.select-item::before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;
	background: rgba(255, 255, 255, 0.75);
}

.select-item::after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;
	transform: scale(0.5);
	transform: scale(0.5);
	transform: scale(0.5);

	width: 26px;
	height: 19px;
	margin-top: -10px;
	margin-left: -13px;

	opacity: 0;
	background: url('../images/check-icon.png') no-repeat;
}

.select-item:hover::after,
.select-item.selected::after {
	transform: scale(1);
	transform: scale(1);
	transform: scale(1);

	opacity: 1;
}

.select-item:hover::before,
.select-item.selected::before {
	opacity: 1;
}

.select-item,
.select-item::before,
.select-item::after {
	transition: all 0.15s ease-in-out;
}

.select-item .select-item__copy-desc {
	position: absolute;
	right: 0;
	bottom: 0;

	padding: 5px;

	font-size: initial;
	font-weight: bold;
}

.select-item .select-item__explore {
	position: absolute;
	top: 0;
	left: 0;

	padding: 5px;

	font-size: initial;
	color: inherit;

	opacity: 0;
}

.select-item .select-item__edit {
	position: absolute;
	bottom: 0;
	left: 0;

	padding: 5px;

	font-size: initial;
	color: inherit;

	opacity: 0;
}

.select-item .select-item__closed-button {
	position: absolute;
	top: 0;
	right: 0;

	padding: 5px;

	font-size: initial;

	opacity: 0;
}

.select-item__closed-button:hover {
	color: $danger;
}

.select-item {
	&:hover {
		.select-item__edit,
		.select-item__explore,
		.select-item__closed-button {
			opacity: 1;
		}
	}
}
