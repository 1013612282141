/* stylelint-disable */
.dropzone {
	display: flex;

	// min-height: 100%;
	flex: 1;
	flex-direction: column;
	align-items: center;

	color: $gray-500;

	outline: none;

	&.dropzone_border {
		border-style: dashed;
		border-width: 2px;
		border-radius: 2px;
		transition: border 0.2s ease-in-out;

		&.dropzone_invalid {
			border-color: $danger;
		}

		&:focus,
		&:hover {
			border-color: $primary;
		}
	}

	&.dropzone_outline {
		cursor: pointer;

		&.dropzone_invalid {
			outline-color: $danger;
			outline-style: solid;
			outline-width: 2px;
		}

		&:hover {
			outline-color: $primary;
			outline-style: dashed;
			outline-width: 2px;

			// transition: outline .2s ease-in-out;
		}
	}

	.dropzone__description {
		display: flex;
		width: 100%;
		height: 100%;

		> h4 {
			align-self: center;

			width: 100%;

			font-weight: 400;
			color: $text-muted;
			text-align: center;
		}
	}
}
