.image-placeholder {
    display: flex;

    // height: 200px;
    // margin: 5px;
    // width: 260px;
    width: 100%;
    height: 100%;
    background-color: $gray-100;
}

.image-placeholder > h4 {
    align-self: center;

    width: 100%;

    font-weight: 400;
    color: $text-muted;
    text-align: center;
}