/* stylelint-disable */
.card .card-header .btn:not(.note-btn) {
	border-radius: 30px;
}

.card-stats {
	.card-body {
		padding: 1rem 1.25rem;
	}

	p {
		margin-right: -1.25rem;
	}
}

.card {
	// overflow: hidden;
	// @include card_base();
	border-radius: 0.375rem;

	.navbar {
		position: static;
	}

	.card-header {
		display: flex;
		align-items: center;
		align-self: center;

		width: 100%;

		grid-row-align: center;

		.btn {
			&:not(.note-btn) {
				border-radius: 30px;
			}
		}
	}

	&.card-primary {
		border-top: 2px solid $primary;
	}

	&.card-secondary {
		border-top: 2px solid $secondary;
	}

	&.card-success {
		border-top: 2px solid $success;
	}

	&.card-danger {
		border-top: 2px solid $danger;
	}

	&.card-warning {
		border-top: 2px solid $warning;
	}

	&.card-info {
		border-top: 2px solid $info;
	}

	&.card-dark {
		border-top: 2px solid $dark;
	}

	&.card-statistic-1 .card-header,
	&.card-statistic-2 .card-header {
		display: block;

		height: auto;
		min-height: auto;
		padding-bottom: 0;

		border-color: transparent;
	}

	&.card-statistic-1 .card-header h4,
	&.card-statistic-2 .card-header h4 {
		line-height: 1.2;
		color: $text-muted;
	}

	&.card-statistic-1 .card-body,
	&.card-statistic-2 .card-body {
		padding-top: 0;
	}

	&.card-statistic-1 .card-body,
	&.card-statistic-2 .card-body {
		padding-bottom: 0;
		font-weight: 600;
		color: $dark;
	}

	&.card-statistic-1,
	&.card-statistic-2 {
		display: inline-block;
		width: 100%;
	}

	&.card-statistic-1 .card-icon,
	&.card-statistic-2 .card-icon {
		float: left;

		width: 80px;
		height: 80px;
		margin: 10px;
		margin-right: 15px;

		line-height: 94px;
		text-align: center;

		border-radius: 3px;

		.ion,
		.fas,
		.far,
		.fab,
		.fal,
		svg {
			font-size: 1.5rem;
			color: #fff;
		}
	}

	&.card-statistic-1 .card-icon {
		line-height: 90px;
	}

	&.card-statistic-2 > .card-icon {
		width: 50px;
		height: 50px;

		//   font-size: 1.375rem;
		margin: 20px;
		line-height: 50px;
	}

	&.card-statistic-1 .card-header,
	&.card-statistic-2 .card-header {
		padding-top: 20px;
		padding-bottom: 0;
	}

	&.card-statistic-2 .card-body {
		padding-top: 20px;
	}

	&.card-statistic-2 .card-header + .card-body,
	&.card-statistic-2 .card-body + .card-header {
		padding-top: 0;
	}

	&.card-statistic-1 .card-header h4,
	&.card-statistic-2 .card-header h4 {
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: 0.5px;
	}

	&.card-statistic-1 .card-header h4 {
		margin-bottom: 0;
	}

	&.card-statistic-2 .card-header h4 {
		margin-bottom: 0;
		text-transform: none;
	}

	&.card-statistic-1 .card-body {
		font-size: 1.25rem;
		line-height: 1.25;
	}

	&.card-statistic-2 {
		.card-chart {
			margin-right: -1px;
			margin-bottom: -15px;
			margin-left: -9px;
			padding-top: 20px;

			canvas {
				height: 90px !important;
			}
		}
	}

	.card-stats {
		display: inline-block;
		width: 100%;
		margin-top: 2px;
		margin-bottom: -6px;

		.card-stats-title {
			padding: 15px 20px;

			font-size: 0.8125rem;
			font-weight: 600;
			letter-spacing: 0.3px;

			background-color: #fff;
		}

		.card-stats-items {
			display: flex;
			align-items: center;
			height: 50px;
		}

		.card-stats-item {
			width: calc(100% / 3);
			padding: 5px 20px;
			text-align: center;

			.card-stats-item-label {
				overflow: hidden;

				margin-top: 4px;

				font-size: 0.75rem;
				text-overflow: ellipsis;
				letter-spacing: 0.5px;
				white-space: nowrap;
			}

			.card-stats-item-count {
				margin-bottom: 8px;
				font-size: 1.25rem;
				font-weight: 600;
				line-height: 1;
			}
		}
	}

	&.card-large-icons {
		display: flex;
		flex-direction: row;

		.card-icon {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;

			width: 150px;

			border-radius: 3px 0 0 3px;

			.ion,
			.fas,
			.far,
			.fab,
			.fal {
				font-size: 3.75rem;
			}
		}

		.card-body {
			padding: 25px 30px;

			h4 {
				font-size: 1.125rem;
			}

			p {
				font-weight: 500;
				opacity: 0.6;
			}

			a.card-cta {
				text-decoration: none;

				i {
					margin-left: 7px;
				}
			}
		}
	}

	&.bg-primary,
	&.bg-danger,
	&.bg-success,
	&.bg-info,
	&.bg-dark,
	&.bg-warning {
		color: #fff;
	}

	&.bg-primary .card-header,
	&.bg-danger .card-header,
	&.bg-success .card-header,
	&.bg-info .card-header,
	&.bg-dark .card-header,
	&.bg-warning .card-header {
		color: #fff;
		opacity: 0.9;
	}
}

// @include media-breakpoint-down(xs) {
//     .card {
//         &.card-large-icons {
//             display: inline-block;

//             .card-icon {
//                 width: 100%;
//                 height: 200px;
//             }
//         }
//     }
// }

// @include media-breakpoint-down(sm) {
//     .card {
//         .card-header {
//             height: auto;
//             flex-wrap: wrap;

//             h4 {

//                 +.card-header-action,
//                 +.card-header-form {
//                     flex-grow: 0;
//                     width: 100%;
//                     margin-top: 10px;
//                 }
//             }
//         }
//     }
// }

// @include media-breakpoint-only(md) {
//     .card {
//         .card-stats {
//             .card-stats-items {
//                 height: 49px;

//                 .card-stats-item {
//                     padding: 5px 7px;

//                     .card-stats-item-count {
//                         font-size: 1rem;
//                     }
//                 }
//             }
//         }

//         &.card-sm-6 {
//             .card-chart {
//                 canvas {
//                     height: 85px !important;
//                 }
//             }
//         }

//         &.card-hero {
//             .card-header {
//                 padding: 20px;
//             }
//         }
//     }
// }
