.item-responsive {
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 100%;
    }

    >.item-responsive__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &.item-responsive_16x9 {
        padding-top: 56.25%; // (9:16)*100%
    }

    &.item-responsive_4x3 {
        padding-top: 75%;

        /* (3:4)*100% */
    }

    &.item-responsive_2x1 {
        padding-top: 50%;

        /* (1:2)*100% */
    }

    &.item-responsive_1x1 {
        padding-top: 100%;

        /* (1:1)*100% */
    }

}