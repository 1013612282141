/* stylelint-disable */

@keyframes copyEffect {
	from {
		color: #fff;
		text-align: center;
		background-color: $success;
	}
	to {
	}
}

.link-field {
	cursor: pointer;
	font-weight: 600;
	transition: all 1s !important;
}

.link-field.empty {
	pointer-events: none;
	cursor: default;
	text-align: center;
	background-color: #e9ecef;
}

.link-field:not(.empty):hover {
	cursor: pointer;
	text-align: center;
}

.link-field.copied {
	animation: copyEffect 1s;
}
