/* stylelint-disable */

// Bootstrap mixins
@import '~bootstrap/scss/mixins';

// Argon mixins
@import '~@creative-tim-official/argon-dashboard-free/assets/scss/custom/mixins';

@mixin shadow($size: 0 0 0.75rem 0, $color: rgba(136, 152, 170, 0.15)) {
	box-shadow: $size $color;
}

@mixin shadow-lighten($color, $amount: 15%) {
	box-shadow: 0 0 0.75rem 0 lighten($color, $amount);
}

@mixin icon-shadow($color, $amount: 15%) {
	box-shadow: 0 0 0.75rem 0 lighten($color, $amount);
}

// DISABLE DEPRECATE MIXIN
@mixin deprecate($name, $deprecate-version, $remove-version, $ignore-warning: false) {
	// @if ($enable-deprecation-messages != false and $ignore-warning != true) {
	//   @warn "#{$name} has been deprecated as of #{$deprecate-version}. It will be removed entirely in #{$remove-version}.";
	// }
}

@mixin card_base($border-radius: $card-border-radius) {
	position: relative;

	margin-bottom: 2rem;

	background-color: $card-bg;
	border: none;
	border-radius: $border-radius;
	box-shadow: $card-box-shadow;
}
