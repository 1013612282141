html {
	scroll-behavior: smooth;
	overflow-x: hidden;

	// font-size: 0.9rem;
}

html,
body,
div#root {
	height: 100%;
}

div#root {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
}

.main-wrapper {
	position: relative;

	display: flex;
	flex-direction: column;

	height: 100%;
	min-height: 100vh;
	margin-left: 230px;
}

.main-content {
	// overflow: hidden;
	position: relative;
	flex: 1 0 auto;
	flex-shrink: 0 !important;

	// padding-top: 1rem;
}

.main-footer {
	// border-top: 1px solid $light;
	display: block;

	width: 100%;
	margin-top: auto;
	padding: 1.5rem 0;

	// margin-top: 30px;
	color: $text-muted;
}

@include media-breakpoint-down(xs) {
	.main-content {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

.main-sidebar {
	@include shadow;
	position: fixed;
	z-index: 880;
	top: 0;
	left: 0;

	overflow: hidden;
	display: flex;
	flex-direction: column;

	width: 230px;
	height: 100%;

	background-color: $bg-color;
	outline: none;
}

.main-sidebar,
.main-navbar,
.main-wrapper {
	transition: all 0.15s;
}

.main-sidebar__head {
	// overflow: hidden;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;

	width: 100%;

	line-height: 2rem;

	// padding: 0 1rem;
	color: $nav-link-light-color;
	text-align: center;

	> .nav-item {
		padding: 1rem;
	}

	.main-sidebar__brand {
		width: 100%;
		margin-right: -1.5rem;
		margin-left: -0.5rem;
		padding: 0;

		// padding-bottom: 0.5rem;

		font-size: 1.15rem;
		font-weight: 700;

		// color: #000 !important;
		color: $nav-link-light-color !important;
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		white-space: nowrap;
	}

	.main-sidebar__brand-logo {
		width: 100%;
		height: 100%;

		background-image: url('./images/wincombo_partners_white.png');
		background-repeat: no-repeat;

		// background-position: center center;
		background-size: contain;
	}
}

.main-sidebar-toggle {
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.main-sidebar-toggle__label {
	display: none;
	width: 100%;
	margin-top: 3px;
	margin-left: 1rem;
}

body.sidebar-gone {
	.main-sidebar {
		left: -230px;
	}
}

.main-sidebar__menu-header {
	overflow: hidden;

	padding: 0.25rem 0.75rem;

	font-size: 0.75rem;
	font-weight: 800;
	color: darken($nav-link-light-color, 50%);
	text-transform: uppercase;
	letter-spacing: 1.3px;
}

.main-sidebar__menu {
	overflow-x: hidden;
	overflow-y: auto;
	display: block;

	margin: 0;
	padding: 0;

	font-size: 14px;
	font-weight: 600;

	> .nav-item {
		display: block;

		.nav-link {
			position: relative;

			display: flex;
			align-items: center;

			width: 100%;
			height: 2.75rem;
			padding: 0 1rem;

			color: darken($nav-link-light-color, 30%);
			text-decoration: none;

			transition: padding-left 0.15s;

			.nav-link__icon {
				font-size: 1.175rem;
			}

			.nav-link__text {
				width: 100%;
				margin-top: 3px;
				margin-left: 1rem;
			}

			&:hover {
				color: $nav-link-light-color;
				background-color: lighten($color: $bg-color, $amount: 10);
				border-radius: $border-radius;
			}

			&.active {
				// background-color: transparent;
				position: relative;

				padding-left: 0.75rem;

				font-weight: 700;
				color: $nav-link-light-color;

				background-color: lighten($color: $bg-color, $amount: 15);
				border-radius: $border-radius;

				// @include icon-shadow($default);

				&::before {
					content: '';

					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);

					width: 0.25rem;
					height: 1.5rem;

					background-color: $nav-link-light-color;
				}
			}
		}
	}
}

body.sidebar-mini {
	.main-sidebar {
		overflow: initial !important;
		width: 4rem;

		.main-sidebar__brand {
			display: none;
		}

		// .main-sidebar-toggle {
		// 	width: 100%;
		// 	margin: 1rem 0.5rem;
		// 	padding: 1rem 1.5rem;
		// 	padding: 0;

		// 	background-image: url('./images/wincombo_logo.png');
		// 	background-repeat: no-repeat;
		// 	background-position: center center;
		// 	background-size: contain;

		// 	> * {
		// 		opacity: 0;
		// 	}
		// }

		.main-sidebar-toggle__label {
			display: none;
		}

		.main-sidebar__menu-header {
			text-align: center;
		}

		.main-sidebar__menu {
			overflow: hidden;

			> .nav-item {
				padding: 0.5rem;

				.nav-link {
					justify-content: center;

					margin-top: -0.5rem;
					margin-bottom: -0.5rem;
					padding: 0;

					border-radius: 3px;

					.nav-link__text {
						display: none;
					}

					&.active {
						@include icon-shadow($bg-color);
						color: $bg-color;
						background-color: white;
						border-radius: $border-radius;

						&::before {
							background-color: transparent;
						}
					}
				}
			}
		}

		&.main-sidebar_hover:hover {
			width: 230px;

			.main-sidebar__head {
				justify-content: flex-start;
			}

			.main-sidebar-toggle__label {
				display: inline;
			}

			.main-sidebar-toggle {
				padding: 1rem;

				// margin: 0;
				// background: none;
				// width: auto;
				// >* {
				//     opacity: 1;
				// }
			}

			.main-sidebar__menu-header {
				text-align: left;
			}

			.main-sidebar__menu {
				> .nav-item {
					padding: 0;

					.nav-link {
						margin: 0;
						padding: 0 1rem;

						.nav-link__text {
							display: block;
						}

						&:hover {
							color: $nav-link-light-color;
							background-color: lighten($color: $bg-color, $amount: 10);
							border-radius: $border-radius;
						}

						&.active {
							position: relative;

							padding-left: 0.75rem;

							font-weight: 700;
							color: $nav-link-light-color;

							background-color: lighten($color: $bg-color, $amount: 15);
							box-shadow: none;

							&::before {
								background-color: $nav-link-light-color;
							}
						}
					}
				}
			}
		}
	}

	.main-wrapper {
		margin-left: 4rem;
	}
}

@include media-breakpoint-down(md) {
	.main-sidebar {
		position: fixed !important;
		z-index: 891;
		margin-top: 0 !important;
	}

	.main-wrapper {
		width: 100% !important;
		margin-left: 0;
	}

	body {
		&.sidebar-show {
			overflow: hidden;
		}

		&.sidebar-show::before {
			content: '';

			position: fixed;
			z-index: 891;
			right: 0;
			left: 0;

			width: 100%;
			height: 100%;

			opacity: 0;
			background-color: #000;

			animation-name: fadeinbackdrop;
			animation-duration: 1s;
			animation-fill-mode: forwards;
		}

		@keyframes fadeinbackdrop {
			to {
				opacity: 0.6;
			}
		}

		@keyframes fadeinbackdrop {
			to {
				opacity: 0.6;
			}
		}
	}
}

.main-wrapper.no-sidebar {
	margin-left: 0;
}
