/* stylelint-disable */

.checklist-entry {
}

.checklist {
	&-item {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;

		padding-left: 0.75rem;

		&::before {
			content: '';

			position: absolute;
			top: 0;
			left: 0;

			width: 3px;
			height: 100%;

			background-color: #5e72e4;
			border-radius: 8px;
		}

		&-checked {
			.checklist-info * {
				text-decoration: line-through;
			}
		}
	}

	&-item {
		&__selected {
			background-color: $gray-100;
		}
	}
}

@each $color, $value in $theme-colors {
	.checklist-item-#{$color}:before {
		background-color: $value;
	}
}
