
.loader {
    position: relative;

    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // height: 100%;
    // width: 100%;
    z-index: 999;
    overflow: hidden;
    flex: auto;

    // min-height: 100vh;
}

.overlay-loader {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    width: 100%;
    height: 100%;

    &_muted {
        background-color: rgba($color: #fff, $alpha: .3);;
    }
}