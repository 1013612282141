.badge-default {
    color: color-yiq(theme-color("default"));
    background-color: theme-color("default");
}

  
  .badge {
    // border-radius: 30px;
    font-size: .75rem;

    // padding: 7px 12px;
    font-weight: 600;
    line-height: unset;
    color: #fff;
    letter-spacing: .3px;
    vertical-align: middle;
  
    &.badge-warning {
      color: #fff;
    }
  
    &.badge-primary {
      background-color: $primary;
    }
  
    &.badge-secondary {
      background-color: $secondary;
    }
  
    &.badge-success {
      background-color: $success;
    }
  
    &.badge-info {
      background-color: $info;
    }
  
    &.badge-danger {
      background-color: $danger;
    }
  
    &.badge-light {
      color: $dark;
      background-color: $light;
    }
  
    &.badge-white {
      color: $dark;
      background-color: white;
    }
  
    &.badge-dark {
      background-color: $dark;
    }
  }
  
  h1 .badge {
    font-size: $h1-font-size;
  }
  
  h2 .badge {
    font-size: $h2-font-size;
  }
  
  h3 .badge {
    font-size: $h3-font-size;
  }
  
  h4 .badge {
    font-size: $h4-font-size;
  }
  
  h5 .badge {
    font-size: $h5-font-size;
  }
  
  h6 .badge {
    font-size: $h6-font-size;
  }
  
  .btn .badge {
    margin-left: 5px;
    padding: 4px 7px;
  
    &.badge-transparent {
      color: #fff;
      background-color: rgba(255, 255, 255, .25);
    }
  }