/* stylelint-disable */

.fade-in {
	animation: fade-in 0.5s ease;
}

.pulsate {
	opacity: 1;
	animation: pulsate 1s ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

.beep {
	position: relative;

	&::after {
		content: '';

		position: absolute;
		top: 2px;
		right: 8px;

		width: 7px;
		height: 7px;

		opacity: 1;
		background-color: $warning;
		border-radius: 50%;

		animation: pulsate 1s ease-out;
		animation-iteration-count: infinite;
	}
}
