/* stylelint-disable */

.content-editable[contenteditable='true'] {
	// border: 1px dashed $gray-500;
	outline: none;

	&:hover,
	&:focus {
		// outline-color: $gray-500;
		outline-color: $primary;

		// border: 1px dashed $primary;
		outline-style: dashed;
		outline-width: 2px;
	}

	&.content-editable_invalid {
		outline-color: $danger;
		outline-style: dashed;
		outline-width: 2px;

		// border: 1px dashed $danger;
	}
}

.content-editable {
	// &:focus {
	//     outline-color: $primary;
	// }
	// &.content-editable_invalid {
	//     outline: dashed;
	//     outline-width: 1px;
	//     outline-color: $danger;
	//     // border: 1px dashed $danger;
	// }
	// &.content-editable_warning {
	//     outline-color: $warning;
	// }
}
