
@media (min-width: 600px) {
    .modal-lg, .modal-xl {
        max-width: calc(100vw - 60px);
    }
}

@media (min-width: 960px) {
    .modal-lg, .modal-xl {
        max-width: 900px;
    }
}

@media (min-width: 1280px) {
    .modal-xl {
        max-width: 1140px;
    }
}
