@import "functions";
@import "variables";
@import "mixins";

// Bootstrap components
@import "bootstrap/components";

// Argon components
@import "argon/components";

// Argon utilities
@import "argon/utilities";

// Custom components
@import "custom/components";

// misc
@import "misc";

// layout
@import "layout";

// animation
@import "animation";

// Custom utilities
@import "utilities";

.fade-enter {
    z-index: 1;
    opacity: 0;
}
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250000ms ease-in;
}

code {
    white-space: pre;
}