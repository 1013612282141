/* stylelint-disable */
.main-slider {
	position: relative;
	overflow: hidden;
	height: 100%;
	border-radius: 25px;
}

.main-slider__wrapper {
	overflow-x: auto;
	overflow-y: visible;
	display: flex;
	flex-direction: column;

	margin-bottom: -2rem;

	// height: 100%;
	// border-radius: 25px;
	// line-height: 0;
	// background-color: color(light);
	padding-bottom: 2rem;

	// line-height: normal;
	// position: static;
	white-space: nowrap;
}

.main-slider__container {
	position: relative;
	left: 0;

	// z-index: 4;
	display: inline-flex;
	align-self: flex-start;

	min-width: 100%;
	height: 100%;

	transition: left 0.5s;

	//display: inline-block;
	// justify-content: center;
}

.main-slider__button {
	position: absolute;
	z-index: 5;
	top: 0;

	height: 100%;

	color: black;

	opacity: 0.8;
	background: #fff;
	border: none;
}

.main-slider__button_prev {
	position: absolute;
	z-index: 5;
	top: 0;
	left: -5px;

	min-width: 2rem;
	height: 100%;

	color: black;

	opacity: 0.8;
	background: #fff;
	border: none;
}

.main-slider__button_next {
	position: absolute;
	z-index: 5;
	top: 0;
	right: -5px;

	min-width: 2rem;
	height: 100%;

	color: black;

	opacity: 0.8;
	background: #fff;
	border: none;
}

.main-slider__container > :first-child {
	margin-left: 0;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

.main-slider__container > :last-child {
	margin-right: 0;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}
