/* stylelint-disable */

body {
	color: color(font);
	background-color: color(body);

	// font-family: 'Montserrat', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// body.fontLoaded {
//     font-family: 'Montserrat', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// }

body.fontLoaded {
	font-family: Nunito, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// html {
//     font-size: 0.9rem;
// }

// @include media-breakpoint-up(lg) {
//     html {
//         font-size: 0.95rem;
//     }
// }

// @include media-breakpoint-up(xl) {
//     html {
//         font-size: 1rem;
//     }
// }

.sidebar-gone-show {
	display: none !important;
}

.overlay {
	position: absolute;
	z-index: 998;
	width: 100%;
	height: 100%;
}

.overlay__center {
	position: absolute;
	z-index: 999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	margin: auto;
}

.description {
	max-width: 180px;
}

@include media-breakpoint-up(lg) {
	.description {
		max-width: 220px;
	}
}

@include media-breakpoint-up(xl) {
	.description {
		max-width: 250px;
	}
}

.nav-link {
	outline: none;
}

b,
strong {
	font-weight: bold;
}

.sweetalert-wrapper > div > div {
	overflow: hidden !important;
}

.table.table_compact {
	td,
	th {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
}

.spinner-border {
	width: 2em;
	height: 2em;
	vertical-align: middle;
}

.spinner-border-sm {
	width: 1em;
	height: 1em;
}

.table.table-sm td,
.table.table-sm th {
	&:first-child {
		padding-left: 1.5rem;
	}

	&:last-child {
		padding-right: 1.5rem;
	}
}

.table {
	margin-bottom: 0;
}

.table td {
	overflow: hidden;
	max-width: 300px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table td:hover {
	text-overflow: unset;
	white-space: unset;
}

.video {
	width: 100%;
	max-width: 1000px;
	height: auto;
}
