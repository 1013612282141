
.divider {
    display: flex;
    align-items: center;

    &::after, &::before {
        flex: 1 1 auto;
        border-top: 1px solid $default;
    }

    >:first-child {
        margin: auto;
        text-transform: uppercase;
    }

    &_center {
        &::after {
            content: ' ';
        }

        &::before {
            content: ' ';
        }

        >:first-child {
            padding-right: .5rem;
            padding-left: .5rem;
            text-align: center;
        }
    }

    &_left {
        &::after {
            content: ' ';
        }

        &::before {
            content: none;
        }

        >:first-child {
            padding-right: .5rem;
            padding-left: 0;
            text-align: left;
        }
    }

    &_right {
        &::after {
            content: none;
        }

        &::before {
            content: ' ';
        }

        >:first-child {
            padding-right: 0;
            padding-left: .5rem;
            text-align: right
        }
    }
}