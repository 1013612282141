.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
}

.custom-switch .custom-control-label::after {
    left: calc(-2.25rem + 2px);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input ~ .custom-control-label {
    font-size: 1rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input ~ .custom-control-label {
    height: auto;
}

.input_no-calendar-picker::-webkit-calendar-picker-indicator {
    // position: absolute;
    pointer-events: none;
    display: none;
    max-width: .5rem;
}

.input_no-calendar-picker {
    appearance: none;
    appearance: textfield;
}

.input_no-calendar-picker::-webkit-inner-spin-button {
    // -webkit-appearance: none;
    margin-right: .5rem;
    margin-left: .5rem;
    
    // pointer-events: none;
}

// .input_no-spin::-webkit-clear-button {
//     -webkit-appearance: none;
    
//     // pointer-events: none;
//  }

// .input_no-spin {
//     -moz-appearance: textfield;
// }

// .input_no-spin::-webkit-outer-spin-button,
// .input_no-spin::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     // pointer-events: none;
// }

// .input_no-spin[type="date"], .input_no-spin[type="time"], .input_no-spin[type="datetime-local"], .input_no-spin[type="month"] {
//     -webkit-appearance: none;
    
//     // pointer-events: none;
// }
.form-group {
    margin-bottom: 1rem;
}