.page-error {
  display: table;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 60px;

  text-align: center;

  .page-inner {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
  }

  h1 {
    font-size: 10em;
    font-weight: 700;
  }

  .page-description {
    font-size: 1.125rem;
    font-weight: 400;
    color: color(fontdark);
  }

  .page-search {
    width: 350px;
    max-width: 100%;
    margin: 40px auto;

    .form-control {
      border-radius: 30px;
    }

    .btn {
      margin-left: 10px;
      border-radius: 30px;
    }
  }
}