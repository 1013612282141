/* stylelint-disable */

.popover {
	@include shadow;
	border-color: transparent;

	.manual-arrow {
		position: absolute;
		bottom: -15px;
		left: 50%;
		transform: translateX(-50%);

		font-size: 1.625rem;
		color: #fff;
	}
}

.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::before {
	border-left-color: #f2f2f2;
}

.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::before {
	border-bottom-color: #f2f2f2;
}

.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::before {
	border-top-color: #f2f2f2;
}

.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::before {
	border-right-color: #f2f2f2;
}

.popover {
	.popover-header {
		padding-top: 10px;
		padding-bottom: 0;
		background-color: transparent;
		border: none;
	}

	.popover-body {
		padding: 15px;
		line-height: 24px;
	}
}

.popover-list {
	overflow: hidden;

	// width: 290px;
	padding: 0;
	border-radius: inherit;

	.popover-item {
		display: inline-block;

		margin-right: -15px;
		margin-left: -15px;

		// width: 100%;
		padding: 15px;

		// padding-top: 15px;
		// padding-bottom: 15px;
		// font-size: .8125rem;
		border-bottom: 1px solid #f9f9f9;

		&:hover,
		&:focus {
			text-decoration: none;
			background-color: color(secondary);
		}

		.time {
			margin-top: 10px;

			font-size: 0.625rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.5px;
		}

		.popover-item-desc {
			margin-left: 60px;
			line-height: 24px;
			color: color(fontdark);
			white-space: normal;

			b {
				font-weight: 600;
				color: #666;
			}

			p {
				margin-bottom: 0;
			}
		}

		&:focus {
			background-color: color(primary);

			.popover-item-desc {
				color: #fff !important;

				b {
					color: #fff !important;
				}
			}
		}

		&:active {
			.popover-item-desc {
				color: #fff;

				b {
					color: #fff;
				}
			}
		}
	}

	.popover-list-icons {
		.popover-item {
			display: flex;

			.popover-item-icon {
				flex-shrink: 0;

				width: 40px;
				height: 40px;

				line-height: 42px;
				text-align: center;

				border-radius: 50%;

				i {
					margin: 0;
				}
			}

			.popover-item-desc {
				margin-left: 15px;
				line-height: 20px;

				.time {
					margin-top: 5px;
				}
			}
		}
	}
}

.notification {
	min-width: 350px;
}

@include media-breakpoint-down(xs) {
	.notification {
		min-width: calc(100vw - 10px);
	}
}
