.tag {
    @include shadow;
    position: relative;

    margin: .125rem;
    padding: .625rem .625rem .5rem;

    line-height: 1.5;
    color: #fff;

    border-radius: .25rem;

    transition: all .15s ease;    

    // padding-right: 1.5rem;
    &:hover, &:focus {
        padding-right: 1.5rem;
    }

    .tag__remove {
        cursor: pointer;

        position: absolute;
        top: 50%;
        transform: translate(0,-50%);

        margin-left: 10px;

        color: #fff;

        &::after {
            content: '×';
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .tag {
        padding-right: 1.5rem;
    }
}