/* stylelint-disable */
.pricing {
	@include card-base;
	text-align: center;

	&.pricing-highlight {
		box-shadow: 0 0 20px $primary;

		.pricing-title {
			color: #fff;
			background-color: $primary;
		}

		.pricing-cta {
			a,
			button,
			span {
				color: #fff;
				background-color: $primary;

				&:hover {
					background-color: color_darken($primary, 10%) !important;
				}
			}
		}
	}

	.pricing-padding {
		padding: 25px;
	}

	.pricing-title {
		display: inline-block;

		padding: 5px 15px;

		font-size: 0.625rem;
		font-weight: 700;
		color: $primary;
		text-transform: uppercase;
		letter-spacing: 2.5px;

		background-color: color_lighten($light, 10%);
		border-radius: 0 0 3px 3px;
	}

	.pricing-price {
		margin-bottom: 20px;

		div:first-child {
			font-size: 2.625rem;
			font-weight: 600;
		}
	}

	.pricing-details {
		display: inline-block;
		text-align: left;

		.pricing-item {
			display: flex;
			margin-bottom: 0.8rem;
			font-size: 0.9rem;

			.pricing-item-icon {
				width: 1.2rem;
				height: 1.2rem;
				margin-right: 10px;

				line-height: 1.2rem;
				color: #fff;
				text-align: center;

				background-color: $success;
				border-radius: 50%;

				i,
				svg {
					width: 100%;
					font-size: 0.6875rem;
				}
			}
		}
	}

	.pricing-cta {
		a,
		button,
		span {
			display: block;

			width: 100%;
			padding: 1rem 2rem;

			font-size: 0.875rem;
			font-weight: 700;
			color: $primary;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 2.5px;

			background-color: color_lighten($light, 10%);
			border: none;
			border-radius: 0 0 3px 3px;
			outline: none;

			transition: all 0.5s;

			.fas,
			.far,
			.fab,
			.fal,
			.ion {
				margin-left: 5px;
			}

			&:hover {
				background-color: $light;
			}
		}
	}

	@media (max-width: 1024px) {
		.pricing-padding {
			padding: 15px;
		}
	}
}
