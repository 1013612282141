.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.cursor-copy {
    cursor: copy;
}

.cursor-default {
    cursor: default;
}

.min-h-100 {
    min-height: 100%;
}

.min-w-100 {
    min-width: 100%;
}

.min-w-200px {
    min-width: 200px;
}

.opasity-0 {
    opacity: 0;
}

.z-index--1 {
    z-index: -1;
}

.width-200 {
    width: 200px;
}

.width-300 {
    width: 300px;
}

.width-150 {
    width: 150px;
}

.min-height-200 {
    min-height: 200px;
}

.height-200 {
    height: 200px
}

.height-300 {
    height: 300px
}

.h-90 {
    height: 90%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.bg-muted {
    background-color: $gray-100;
}

@include media-breakpoint-up(md) {
    .h-md-100 {
        height: 100%;
    }

    .h-md-50 {
        height: 50%;
    }
}

.is-invalid {
    border-color: $warning;
}

.is-valid {
    border-color: $success;
}

.mw-300 {
    max-width: 300px;
}

.no-shadow {
    box-shadow: none;
}

.border-radius-30 {
    border-radius: 30px !important;
}

.z-index-4 {
    z-index: 4 !important;
}

.z-index-5 {
    z-index: 5 !important;
}

.z-index-6 {
    z-index: 6 !important;
}

.z-index-7 {
    z-index: 7 !important;
}

.z-index-8 {
    z-index: 8 !important;
}

.z-index-9 {
    z-index: 9 !important;
}

.z-index-10 {
    z-index: 10 !important;
}

.z-index-11 {
    z-index: 11 !important;
}

.z-index-12 {
    z-index: 12 !important;
}

.z-index-13 {
    z-index: 13 !important;
}

.z-index-14 {
    z-index: 14 !important;
}

.z-index-15 {
    z-index: 15 !important;
}

@keyframes pulse-search-icon {
    0% {
        color: $gray-500;
    }

    50% {
        color: $warning;
    }

    100% {
        color: $gray-500;
    }
}

.pulse-search-icon {
    animation: pulse-search-icon 1s ease infinite;
}

.form-control-height {
    height: calc(1.5em + 0.85rem + 5px);
}

.row_sm {
    margin-right: -10px;
    margin-left: -10px;

    > div[class*='col-'] {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.bg-opacity-light {
    background-color: rgba($color: white, $alpha: 0.5)
}

.bg-opacity-dark {
    background-color: rgba($color: black, $alpha: 0.3)
}


.creature::before, .creature::after {
    content: '';

    position: absolute;
    top: 40px;

    width: 30px;
    height: 40px;

    background: #000;
    border-radius: 50%;

    animation: eye 3s ease-in-out infinite;
   }
.creature::before { left: 55px; }
.creature::after { right: 55px; }

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ct-content {
    max-width: 680px;
}

.outline-none {
    outline: none !important;
}

.text-placeholder {
    color: $gray-500;
}